import { styled } from '@hexa-ui/theme';

const Wrapper = styled('div', {
  paddingTop: '30px',
  display: 'flex',
  flexDirection: 'column',
  gap: '$4',
  marginBottom: '$6',
});

const HeaderContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const TitleContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$4',
});

const SectionContainer = styled('div', {
  display: 'flex',
  gap: '$6',
  alignItems: 'center',
});

export const Styled = {
  Wrapper,
  HeaderContainer,
  TitleContainer,
  SectionContainer,
};
