import { Card as CardHexa, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const commonStyles = {
  fontFamily: 'Work Sans',
  fontWeight: '400',
};

const ReportsQuantity = styled(Paragraph, {
  opacity: '56%',
  fontSize: '14px',
  ...commonStyles,
});

const Card = styled(CardHexa, {
  '& table:first-of-type': {
    borderRadius: '16px 16px 0 0',
  },
});

const ReportsTable = styled('table', {
  width: '100%',
  borderCollapse: 'collapse',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '$neutral20',
    transition: 'background-color 0.3s ease',
  },
});

const RowWrapper = styled('tr', {
  height: '56px',
  verticalAlign: 'middle',
  borderBottom: '1px solid #E0E0E0',
});

const FirstColumn = styled('td', {
  width: '4%',
  textAlign: 'center',
});

const SecondColumn = styled('td', {
  width: '93%',
  fontSize: '16px',
  ...commonStyles,
});

const ThirdColumn = styled('td', {
  width: '3%',
  cursor: 'pointer',
});

const PaginationWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'right',
  margin: '16px 16px 16px 0px',
});

export const Styled = {
  Card,
  ReportsQuantity,
  ReportsTable,
  RowWrapper,
  FirstColumn,
  SecondColumn,
  ThirdColumn,
  PaginationWrapper,
};
