import { Button, Heading, IconButton } from '@hexa-ui/components';
import { ArrowLeft } from '@hexa-ui/icons';
import { ANALYTICS_ADMIN_GROUP } from 'Assets/Constants';
import MFERoutes from 'Config/MFERoutes';
import useAppEvents from 'Hooks/segment/useAppEvents/useAppEvents';
import { useReports } from 'Hooks/useReports/useReports';
import useUserGroups from 'Hooks/useUserGroups/useUserGroups';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { hasAdminPermission } from 'Utils/hasAdminPermission/hasAdminPermission';
import { BreadcrumbComponent } from './Breadcrumb/Breadcrumb';
import { Styled } from './HeaderContainer.style';
import { HeaderContainerProps } from './HeaderContainer.types';
import { WorkspaceSelectorCard } from './WorkspaceSelectorCard/WorkspaceSelectedCard';

export function HeaderContainer({
  pageTitle,
  goBackButtonUrl,
  showAnalyticsAdminButton = false,
}: Readonly<HeaderContainerProps>): React.ReactElement {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { workspaceSelected } = useReports();

  const userGroups = useUserGroups();
  const hasAnalyticsAdminPermission = hasAdminPermission(userGroups, ANALYTICS_ADMIN_GROUP);

  /* istanbul ignore next */
  const handleGoBack = () => {
    history.push(goBackButtonUrl);
  };

  /* istanbul ignore next */
  const handleClick = () => {
    history.push(MFERoutes.ANALYTICS_ADMIN);
  };

  const { triggerPageViewed } = useAppEvents();

  const pathSegments = window.location.pathname.split('/');
  const lastSegment = pathSegments[pathSegments.length - 1];

  useEffect(() => {
    triggerPageViewed({
      URL: window.location.href,
      path: window.location.pathname,
      category: lastSegment.toUpperCase(),
      currentVendorId: workspaceSelected.vendorId,
      currentVendorName: workspaceSelected.vendorName,
      businessModel: workspaceSelected.businessModel,
      currentCountry: workspaceSelected.country,
    });
  }, []);

  return (
    <Styled.Wrapper>
      <BreadcrumbComponent />

      <Styled.HeaderContainer>
        <Styled.TitleContainer>
          {goBackButtonUrl && (
            <IconButton variant="tertiary" icon={ArrowLeft} onClick={handleGoBack} />
          )}
          <Heading size="H2">{pageTitle}</Heading>
        </Styled.TitleContainer>
        <Styled.SectionContainer>
          {hasAnalyticsAdminPermission && showAnalyticsAdminButton && (
            <Button onClick={handleClick}>
              {formatMessage({ id: 'HEADER.ANALYTICS_BUTTON' })}
            </Button>
          )}
          <WorkspaceSelectorCard />
        </Styled.SectionContainer>
      </Styled.HeaderContainer>
    </Styled.Wrapper>
  );
}
