import { Card } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

const CardContainer = styled(Card, {
  display: 'flex',
  flexDirection: 'row !important',
  height: '56px',
  alignItems: 'center',
  padding: '$2 0',
  width: 'fit-content',
});

const CardItem = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '16px 24px',
});

export const Styled = {
  CardContainer,
  CardItem,
};
