export enum PermissionRuleType {
  COUNTRY = 'Country',
  BUSINESS_MODEL = 'BusinessModel',
  VENDOR_ID = 'VendorID',
  MANUFACTURER_ID = 'ManufacturerID',
}

export type Permission = {
  id: string;
  name: string;
  thumbnail: {
    type: string;
    value: string;
  };
  rule?: {
    type: PermissionRuleType;
    value: string;
  };
  country?: string;
};

export enum WorkspaceRLSSchemaValue {
  VENDOR_ID = 'VendorId',
  COUNTRY = 'Country',
  LANGUAGE = 'Language',
  USER_ID = 'UserId',
}

export type Workspace = {
  id: string;
  slug: string;
  trackingId: string;
  title: Record<string, string>;
  description: Record<string, string>;
  permissions: Permission[];
  region?: string;
  rowLevelSecurity: {
    role: string;
    schema: string;
  };
};

export interface Workspaces {
  workspaces: Workspace[];
}

export const MOCK_WORKSPACES: Workspaces = {
  workspaces: [
    {
      id: '111',
      slug: 'seller',
      trackingId: 'TEST',
      title: {
        'id-ID': 'Seller',
        'en-US': 'Seller',
        'es-419': 'Seller',
        'pt-BR': 'Seller',
      },
      description: {
        'id-ID': '',
        'en-US': '',
        'es-419': '',
        'pt-BR': '',
      },
      permissions: [
        {
          id: '222',
          name: 'Rule',
          thumbnail: {
            type: 'Icon',
            value: 'AddTag',
          },
        },
      ],
      region: 'Global',
      rowLevelSecurity: {
        role: 'TEST',
        schema: 'TEST',
      },
    },
  ],
};

export const MOCK_WORKSPACES_WITHOUT_PERMISSIONS: Workspaces = {
  workspaces: [
    {
      id: '111',
      slug: 'seller',
      trackingId: 'TEST',
      title: {
        'id-ID': 'Seller',
        'en-US': 'Seller',
        'es-419': 'Seller',
        'pt-BR': 'Seller',
      },
      description: {
        'id-ID': '',
        'en-US': '',
        'es-419': '',
        'pt-BR': '',
      },
      permissions: [],
      region: 'Global',
      rowLevelSecurity: {
        role: 'TEST',
        schema: 'TEST',
      },
    },
  ],
};
