import MessageMap from 'I18n/i18n';

const enUS: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only English option available',
  },

  SELECTED_LANGUAGE: 'en-US',

  // NEED COPYDECK
  MAIN_PAGE: {
    TITLE: 'Analytics',
    BTGUIDE_SECTION: {
      TITLE: 'Business transformation guide',
      DESCRIPTION: 'A step-by-step guide to maximize value with BEES.',
      LINK: 'Go to Business transformation guide',
    },
    REPORT_SECTION: {
      TITLE: 'Reports',
      SUBTITLE: 'Explore detailed reports on different themes.',
      BUTTON: 'Show all reports',
    },
    WORKSPACE_SECTION: {
      TITLE: 'Workspaces',
      DESCRIPTION: 'Access different sets of data available to you.',
      BUTTON: 'Show all workspaces',
    },
    EMPTY_STATE: {
      TITLE: 'Oops',
      DESCRIPTION: 'Apparently there is no workspace created or available for you.',
    },
  },

  // NEED COPYDECK
  CARD_SECTION: {
    EMPTY_MESSAGE: 'Oops, sorry! No results found.',
  },

  WORKSPACES_PAGE: {
    TITLE: 'Workspaces',
    SEARCHFIELD: {
      PLACEHOLDER: 'Search by workspace name',
    },
  },

  HEADER: {
    ANALYTICS_BUTTON: 'Analytics admin',
  },

  WORKSPACE_SELECTED_CARD: {
    CHANGE: 'Change',
    NO_SEGMENT: 'No segment selected',
  },

  CHANGE_WORKSPACE_MODAL: {
    TITLE: 'Change workspace',
    DESCRIPTION: 'Select a workspace and segment.',
    WORKSPACE: {
      LABEL: 'Workspace',
      PLACEHOLDER: 'Select workspace',
    },
    SEGMENT: {
      LABEL: 'Segment',
      PLACEHOLDER: 'Select segment',
    },
    COUNTRY: {
      LABEL: 'Country',
      PLACEHOLDER: 'Select country',
    },
    SERVICE_MODEL: {
      LABEL: 'Business model',
      PLACEHOLDER: 'Select a business model',
    },
    VENDOR: {
      LABEL: 'Vendor',
      PLACEHOLDER: 'Select a vendor',
      LOADING: 'Loading...',
    },
    CONFIRM_BUTTON: 'Change',
  },

  WORKSPACE_CARD: {
    SINGULAR_TEXT: 'segment',
    PLURAL_TEXT: 'segments',
  },

  BREADCRUMBS: {
    REPORTS: 'Get Started',
  },

  REPORTS_LIST_PAGE: {
    TITLE: 'Reports',
    SEARCH: {
      PLACEHOLDER: 'Search by theme or report name',
    },
    NOT_FOUND_STATE: {
      TITLE: 'No results found',
      TEXT: 'Check your spelling or try using different search terms.',
    },
    TOAST: {
      FIRST_PART: "You're now in the",
      LAST_PART: 'workspace',
    },
    REPORTS_QUANTITY: ' reports',
  },

  REPORTS_PAGE: {
    REPORTS_PAGE_TITLE: 'Data and analytics',
    REPORTS_PAGE_VENDOR: 'Vendor',
    CONTENT_REPORTS_TAB_VIEW:
      'Measure your performance across all delivered orders within the selected timeframe.',
  },

  GLOBAL_OVERVIEW_PAGE: {
    GLOBAL_OVERVIEW_PAGE_TITLE: 'Global overview',
  },

  CONTAINER_REPORTS: {
    CONTAINER_ERROR: 'Something went wrong',
    CONTAINER_ERROR_LABEL: "Sorry, we're having technical issues. Please try again.",
  },

  SELECT_RANGE_DATE_CSV_MODAL: {
    TITLE: 'Export CSV',
    DESCRIPTION: 'Select the period for the download.',
    YEAR_LABEL: 'Year',
    MONTH_LABEL: 'Month',
    NO_INFO_AVAILABLE:
      "There's no information for the selected period. Please try a different date.",
    CANCEL: 'Cancel',
    DOWNLOAD: 'Download',
  },

  BECOME_PREMIUM_MODAL: {
    TITLE: 'Business insights taken to the next level',
    FIRST_DESCRIPTION: 'Get access to CSV export and many other features to improve your analysis.',
    BUTTON_BECOME: 'Become a Gold Partner',
    BUTTON_LATER: 'Later',
    MODAL_STYLE: 'EN',
  },

  CSV_DOWNLOAD_SELECT: {
    PLACEHOLDER: 'Export data',
  },

  CSV_SILVER_MODAL: {
    TITLE: 'Your free trial will expire soon',
    FIRST_DESCRIPTION:
      'This CSV you are about to download is a courtesy from our membership program.',
    SECOND_DESCRIPTION:
      'To keep access to special insights tailored for your company in the future, you’ll have to upgrade to Gold Partner.',
    BUTTON: 'DOWNLOAD FILE',
    NO_INFO_AVAILABLE: "There's no information for the period.",
  },

  HELP_CENTER_MODAL: {
    TITLE: 'How to access a CSV file',
    DESCRIPTION:
      'Not familiar with CSV files? Check a step-by-step guide on how to use a CSV file.',
    LINK: 'Show guide to CSV files',
    PROCEED_BUTTON: 'Proceed to download',
  },

  GET_STARTED: {
    LOADING: 'Loading',
    TITLE: 'Get started',
    PARAGRAPH: 'Select a vendor to see their data and insights.',
    NEXT: 'Next',
  },

  SELECTED_VENDOR: {
    CHANGE: 'Change',
    TITLE: 'You are viewing',
  },

  SELECT_TOOLTIP: {
    OPEN: 'Open',
    CLEAR: 'Clear',
  },

  MONTH: {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
  },

  COMMON: {
    CANCEL: 'Cancel',
    CONFIRM: 'Confirm',
  },
  LOADING: {
    TITLE: 'Loading',
    PARAGRAPH: 'We are preparing the page for you.',
  },
  SELECT_VENDOR: {
    TITLE: 'Select a vendor',
  },
  UNAUTHORIZED: {
    ERROR_TITLE: 'Hmm… Access denied!',
    ERROR_DESCRIPTION: 'It looks like you don’t have permission to access this page.',
  },
  EXPORT_FILE: {
    SOMETHING_WRONG: 'Something went wrong. Please try again.',
    PREPARING_DOWNLOAD: 'Preparing CSV file for download.',
    SUCCESS_DOWNLOAD: 'Download completed successfully.',
  },
  // NEED COPY DECK
  BECOME_PREMIUM: {
    PAGE_TITLE: 'Become Premium',
    TITLE_NORMAL: `We've got a plan\nthat's perfect for`,
    TITLE_HIGHLIGHTED: 'your business',
    SUBTITLE: 'Power up your analysis with the right membership for you company.',
    SILVER_PARTNER_CARD: {
      TITLE: 'Silver Partner',
      DESCRIPTION: 'For businesses starting in the digital marketplace world.',
      BIG_TEXT: 'Basic',
      BUTTON_LABEL: 'Current plan',
    },
    GOLD_PARTNER_CARD: {
      TITLE: 'Gold Partner',
      DESCRIPTION:
        'For businesses with advanced data intelligence teams that want to invest in targeted audiences.',
      BIG_TEXT: 'Premium',
      BUTTON_LABEL: 'Contact us',
    },
    METRICS_TABLE: {
      HEADER: {
        METRICS: 'Metrics',
        SILVER: 'Silver',
        GOLD: 'Gold',
      },
      ROWS: {
        UNITS_DELIVERED: 'Units Delivered',
        ORDERS_DELIVERED: 'Orders Delivered',
        BUYERS: 'Buyers',
        UNIT_DELIVERED_BY_PRODUCT: 'Unit Delivered by Product',
        UNITS_DELIVERED_BY_CITY_AND_STATE: 'Units Delivered by City and State',
        UNITS_SOLD_BY_DISTRIBUTION_CENTER: 'Units Sold by Distribution Center',
        TOP_5_PRODUCTS: 'Top 5 Products',
        MONTHLY_EVOLUTION_OF_ORDERS: 'Monthly evolution of Orders',
        REVENUE: 'Revenue',
        AVERAGE_REVENUE_PER_ORDER: 'Average Revenue per Order',
        AVERAGE_REVENUE_PER_BUYER: 'Average Revenue per Buyer',
        REVENUE_BY_REGION: 'Revenue by Region',
        MONTHLY_SALES_REVENUE: 'Monthly Sales Revenue',
        CURRENT_MONTH_VS_LAST_MONTH: 'Current Month vs Last Month',
        ORDERS_VS_REVENUE_STATE: 'Orders vs Revenue/State',
        ORDERS_VS_REVENUE_SEGMENT: 'Orders vs Revenue/Segment',
        ORDERS_VS_REVENUE_PRODUCT: 'Orders vs Revenue/Product',
        REVENUE_BY_PRODUCT: 'Revenue by Product',
        REVENUE_BY_SEGMENT: 'Revenue by Segment',
        REVENUE_BY_CHANNEL: 'Revenue by Channel',
      },
    },
    CSV_DOWNLOAD_TABLE: {
      HEADER: {
        CSV_DOWNLOAD: 'CSV Download',
        SILVER: 'Silver',
        GOLD: 'Gold',
      },
      ROWS: {
        DATE: 'Date',
        GROSS_VALUE: 'Gross Value',
        REGION_STATE_AND_CITY: 'Region (State and City)',
        DISTRIBUTION_CENTER: 'Distribution Center',
        SEGMENT: 'Segment',
        SUB_SEGMENT: 'Sub-Segment',
        CHANNEL: 'Channel',
      },
    },
  },
  REVENUE_SILVER_PARTNER: {
    TITLE: 'Business insights taken to the',
    TITLE_NEXT_LEVEL: 'next level',
    DESCRIPTION:
      'With our premium insights you can discover powerful analysis that will unleash all the potential of your business.',
    BUTTON: 'Become a Gold Partner',
  },
  // NEED COPYDECK
  BADGES: {
    EXCLUSIVE_FOR_GOLD_PARTNER: 'Exclusive for Gold Partners',
  },
  // NEED COPYDECK
  TIER_IDENTIFICATION: {
    GOLD: 'Gold Partner',
    SILVER: 'Silver Partner',
  },
  CONTACT_US: {
    TOAST_SUCCESS: 'Request successfully sent! Our team will contact you shortly.',
    TOAST_ERROR: 'Something went wrong. Please try again.',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BO: 'Bolivia',
    BR: 'Brazil',
    CL: 'Chile',
    CO: 'Colombia',
    DO: 'Dominican Republic',
    EC: 'Ecuador',
    ID: 'Indonesia',
    MX: 'Mexico',
    PA: 'Panama',
    PE: 'Peru',
    PH: 'Philippines',
    PY: 'Paraguay',
    ZA: 'South Africa',
    CH: 'Switzerland',
    GLOBAL_OVERVIEW: 'Global overview',
  },
  COUNTRY_SELECT: {
    PLACEHOLDER: 'Select a country',
  },
  SERVICE_MODELS: {
    '1P': '1P',
    '3P-3L-SS': '3P Pro',
    '3P-3L-SF': 'DTaaS',
    '3P': 'DTaaS',
  },
  SERVICE_MODEL_SELECT: {
    PLACEHOLDER: 'Select a business model',
  },
  VENDOR_SELECT: {
    PLACEHOLDER: 'Select a vendor',
    LOADING: 'Loading...',
  },

  EMBED_REPORT: {
    LOADING_EMBED_REPORT: {
      TITLE: 'Fetching the data...',
      BODY: 'Step 1 out of 3',
    },

    LOADING_POWERBI: {
      TITLE: 'Loading report...',
      BODY: 'Step 2 out of 3',
    },

    LOADING_DATA: {
      TITLE: 'We are almost there',
      BODY: 'Step 3 out of 3',
    },
  },

  SIDEBAR: {
    GET_START: 'Get started',
    WORKSPACES: 'Workspaces',
    ANALYTICS_ADMIN: 'Analytics Admin',
    MAIN_PAGE: 'Main page',
  },

  EXTERNAL_REPORTS_PAGE: {
    TITLE: 'Additional reports & documentation',
    DESCRIPTION: 'Access additional reports from other BEES products.',
  },

  KPI_DOCUMENTATION_PAGE: {
    TITLE: 'Understanding our KPIs',
    SUBTITLE:
      'This page explains what our KPIs (key performance indicators) are and how they are calculated. To find a specific KPI, use the search bar or navigate through the tabs below.',
    COLUMNS: {
      NAME: 'KPI name',
      DESCRIPTION: 'Description',
      METHODOLOGY: 'Calculation methodology',
    },
    EMPTY_STATE: 'Oops, sorry! No results found.',
    SEARCH_FIELD: 'Search by KPI or keyword',
    SEARCH_RESULTS: {
      EMPTY_STATE_TITLE: 'No results found.',
      EMPTY_STATE_TEXT: 'Check your spelling or try using different search terms.',
      COUNT_RESULT_MESSAGE: ' results for ',
      UNIT_RESULT_MESSAGE: '1 result for ',
    },
  },

  TOAST: {
    SUCCESS: 'Success! Your changes have been saved',
    ERROR: 'Something went wrong. Please try again later',
    ID_ALREADY_EXISTS: 'The id already exists. Please choose another one.',
  },
};

export default enUS;
