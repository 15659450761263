/* eslint-disable max-params */
import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { EmbedReportResponse } from 'Domain/NewEmbedReport';
import { getEmbedReport, getEmbedReportProps } from './getEmbedReport';

/* istanbul ignore next */
export const useGetEmbedReport = ({
  lang,
  workspaceId,
  reportId,
  permissionId,
  vendorId,
  country,
}: getEmbedReportProps): UseQueryResult<EmbedReportResponse> =>
  useQuery({
    queryKey: ['getEmbedReport', lang, workspaceId, reportId, permissionId, vendorId],
    queryFn: () => getEmbedReport({ lang, workspaceId, reportId, permissionId, vendorId, country }),
    enabled: !!lang && !!workspaceId && !!reportId,
    refetchOnWindowFocus: false,
    staleTime: 7500,
  });
