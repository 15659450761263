import BTGuideSection from 'Components/BTGuideSection/BTGuideSection';

import ErrorPage from 'Components/ErrorPage/ErrorPage';
import { HeaderContainer } from 'Components/HeaderContainer/HeaderContainer';
import Loading from 'Components/Loading/Loading';
import PageContainer from 'Components/PageContainer/PageContainer';
import { Sidebar } from 'Components/SidebarNew/Sidebar';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import React from 'react';
import { useIntl } from 'react-intl';
import { useBTGuideById } from 'Services/GetBTGuide/useBTGuide';
import { useGetReportsByWorkspace } from 'Services/GetReportsByWorkspace/useGetReportsByWorkspace';
import { useGetWorkspaces } from 'Services/GetWorkspaces/useGetWorkspaces';
import { formatMenuItems } from 'Utils/formatMenuItems/formatMenuItems';
import { Styled } from './MainPage.style';
import { ReportsSection } from './ReportsSection/ReportsSection';
import { WorkspaceSection } from './WorkspaceSection/WorkspaceSection';

export function MainPage(): React.ReactElement {
  const { formatMessage } = useIntl();
  const SELECTED_LANGUAGE = formatMessage({ id: 'SELECTED_LANGUAGE' });

  const { workspaceData: selectedWorkspace } = useWorkspaceContext();

  const {
    data: btGuideUrl,
    isError: isBTGuideError,
    isLoading: isBtGuideLoading,
  } = useBTGuideById(selectedWorkspace?.vendorId, selectedWorkspace?.country ?? 'BR');

  const { data, isLoading: AllWorkspacesIsLoading, error } = useGetWorkspaces();
  const allWorkspaces = data?.workspaces || [];

  const { data: AllReportsMenuItems, isLoading: AllReportsIsLoading } = useGetReportsByWorkspace(
    selectedWorkspace?.workspace?.id,
    selectedWorkspace?.segment?.id,
    selectedWorkspace?.segment?.country
  );

  const sortedMenuItems = formatMenuItems(AllReportsMenuItems?.menuItems || []);

  if (!AllWorkspacesIsLoading && allWorkspaces?.length === 0) {
    return (
      <ErrorPage
        action="ANALYTICS_ADMIN"
        title={formatMessage({ id: 'MAIN_PAGE.EMPTY_STATE.TITLE' })}
        message={formatMessage({ id: 'MAIN_PAGE.EMPTY_STATE.DESCRIPTION' })}
      />
    );
  }

  if (error || !selectedWorkspace?.workspace?.id || isBtGuideLoading) {
    return (
      <PageContainer maxWidth="xl" sidebar={false}>
        <Loading container />
      </PageContainer>
    );
  }

  return (
    <PageContainer maxWidth="xl">
      <Sidebar reportsMenuItems={sortedMenuItems} />
      <>
        <HeaderContainer
          pageTitle={formatMessage({ id: 'MAIN_PAGE.TITLE' })}
          showAnalyticsAdminButton
        />
        <Styled.Container>
          <Styled.Row>
            <Styled.MainSection hasBTGuidePermission={!isBTGuideError}>
              {selectedWorkspace?.workspace?.id && (
                <ReportsSection
                  hasBTGuide={!isBTGuideError}
                  workspaceNameSelected={selectedWorkspace.workspace.title[SELECTED_LANGUAGE]}
                  segmentIdSelected={selectedWorkspace?.segment?.id}
                  allReportsByTheme={sortedMenuItems}
                  isLoading={AllReportsIsLoading}
                />
              )}
            </Styled.MainSection>
            {!isBTGuideError && (
              <BTGuideSection selectedWorkspace={selectedWorkspace} btGuideUrl={btGuideUrl.url} />
            )}
          </Styled.Row>
        </Styled.Container>

        <WorkspaceSection
          allWorkspaces={allWorkspaces}
          selectedWorkspace={selectedWorkspace.workspace.id}
        />
      </>
    </PageContainer>
  );
}
