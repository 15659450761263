import { styled } from '@hexa-ui/theme';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  marginBottom: '$6',
});

const Row = styled('div', {
  display: 'flex',
  gap: '$6',
});

const MainSection = styled('div', {
  '> div': {
    height: '100%',
  },
  flexGrow: 1,
  variants: {
    hasBTGuidePermission: {
      true: {
        width: '70%',
      },
      false: {
        width: '100%',
      },
    },
  },
});

const ContainerCarousel = styled('div', {
  variants: {
    hideSpaces: {
      true: {
        '& li > div': {
          padding: '24px 2px 28px',
        },
      },
    },
  },
});

const CenterContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

export const Styled = {
  ContainerCarousel,
  Container,
  Row,
  MainSection,
  CenterContainer,
};
