import { EmptyState } from 'Components/EmptyState/EmptyState';
import GenericErrorView from 'Components/GenericError/GenericErrorView';
import KPIDocumentationSearchField from 'Components/KPIDocumentationSearchField/KPIDocumentationSearchField';
import KPIDocumentationSearchResults from 'Components/KPIDocumentationSearchResults/KPIDocumentationSearchResults';
import { SearchFieldContainer } from 'Components/KPIDocumentationSearchResults/KPIDocumentationSearchResults.style';
import KPIDocumentationTable from 'Components/KPIDocumentationTable/KPIDocumentationTable';
import { KPITableLoader } from 'Components/KPIDocumentationTable/KPIDocumentationTable.style';
import KPIDocumentationTabs from 'Components/KPIDocumentationTabs/KPIDocumentationTabs';
import { KPITabsLoader } from 'Components/KPIDocumentationTabs/KPIDocumentationTabs.style';
import KPIDocumentationWrapper from 'Components/KPIDocumentationWrapper/KPIDocumentationWrapper';
import { generateReportsPath, getKPIDocumentationRoute } from 'Config/MFERoutes';
import { IKPIDocumentationContext, KPIDocumentationContext } from 'Context/KPIDocumentationContext';
import { ITrackingContext, TrackingContext } from 'Context/TrackingContext';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import useKPIDocumentationPage, {
  KPIDocumentationPageStatus,
} from 'Pages/KPIDocumentationPage/useKPIDocumentationPage';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Redirect, generatePath } from 'react-router-dom';

function KPIDocumentationPage(): React.ReactElement | null {
  const { formatMessage } = useIntl();
  const { vendorId, selectedCountry } = useSelectedVendor();

  const { status, menuItems, kpis, selectedMenuItem } = useKPIDocumentationPage();

  const isLoading =
    status === KPIDocumentationPageStatus.INITIAL_LOADING ||
    status === KPIDocumentationPageStatus.LOADING_KPIS;

  const KPIDocumentationContextValue: IKPIDocumentationContext | null = useMemo(() => {
    if (selectedMenuItem) {
      return { selectedMenu: selectedMenuItem };
    }
    return null;
  }, [selectedMenuItem]);

  const trackingContextValue: ITrackingContext = useMemo(
    () => ({
      reportSubType: null,
      reportType: null,
      tabName: selectedMenuItem?.trackingId ?? '',
      vendorId: null,
      vendorName: null,
      country: null,
      businessModel: null,
    }),
    [selectedMenuItem?.trackingId]
  );

  if (isLoading) {
    return (
      <KPIDocumentationContext.Provider value={KPIDocumentationContextValue}>
        <KPIDocumentationWrapper isLoading>
          <KPITabsLoader />
          <KPITableLoader />
        </KPIDocumentationWrapper>
      </KPIDocumentationContext.Provider>
    );
  }

  if (status === KPIDocumentationPageStatus.UNAUTHORISED) {
    return <Redirect to={generateReportsPath({ vendorId, country: selectedCountry })} />;
  }

  if (status === KPIDocumentationPageStatus.ERROR) {
    return (
      <KPIDocumentationContext.Provider value={KPIDocumentationContextValue}>
        <KPIDocumentationWrapper>
          <GenericErrorView />
        </KPIDocumentationWrapper>
      </KPIDocumentationContext.Provider>
    );
  }

  if (status === KPIDocumentationPageStatus.NO_MENU_ITEMS) {
    return (
      <KPIDocumentationContext.Provider value={KPIDocumentationContextValue}>
        <KPIDocumentationWrapper>
          <EmptyState
            css={{ padding: '$24 0', margin: '0 auto' }}
            text={formatMessage({ id: 'KPI_DOCUMENTATION_PAGE.EMPTY_STATE' })}
          />
        </KPIDocumentationWrapper>
      </KPIDocumentationContext.Provider>
    );
  }

  if (status === KPIDocumentationPageStatus.INVALID_MENU_ITEM) {
    return (
      <Redirect
        to={generatePath(getKPIDocumentationRoute(), {
          menuItem: menuItems[0].slug,
          country: selectedCountry,
          vendorId,
        })}
      />
    );
  }

  if (status === KPIDocumentationPageStatus.LOADED) {
    return (
      <KPIDocumentationContext.Provider value={KPIDocumentationContextValue}>
        <TrackingContext.Provider value={trackingContextValue}>
          <KPIDocumentationWrapper>
            <KPIDocumentationTabs menuItems={menuItems} selectedMenuItem={selectedMenuItem} />
            <SearchFieldContainer>
              <KPIDocumentationSearchField key={selectedMenuItem.slug} />
            </SearchFieldContainer>
            <KPIDocumentationTable kpis={kpis} isSearchable={false} />
          </KPIDocumentationWrapper>
        </TrackingContext.Provider>
      </KPIDocumentationContext.Provider>
    );
  }

  if (status === KPIDocumentationPageStatus.SEARCH_RESULTS) {
    return (
      <KPIDocumentationContext.Provider value={KPIDocumentationContextValue}>
        <KPIDocumentationWrapper>
          <KPIDocumentationSearchResults
            menuItems={menuItems}
            selectedMenuItem={selectedMenuItem}
          />
        </KPIDocumentationWrapper>
      </KPIDocumentationContext.Provider>
    );
  }

  return null;
}

export default KPIDocumentationPage;
