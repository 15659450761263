import KPIDocumentationPageHeader from 'Components/KPIDocumentationPageHeader/KPIDocumentationPageHeader';
import { KPIHeaderLoader } from 'Components/KPIDocumentationPageHeader/KPIDocumentationPageHeader.style';
import PageContainer from 'Components/PageContainer/PageContainer';
import Sidebar from 'Components/Sidebar/Sidebar';
import { isGlobalPartner, isMultiVendor } from 'Domain/User';
import useSelectedVendor from 'Hooks/useSelectedVendor/useSelectedVendor';
import useReportsMFEConfig from 'Services/GetReportsMFEConfig/useReportsMFEConfig';
import React from 'react';
import { useParams } from 'react-router-dom';

export default function KPIDocumentationWrapper({
  children,
  isLoading,
}: {
  children: React.ReactNode;
  isLoading?: boolean;
}): React.ReactElement {
  const { vendorId, selectedCountry } = useSelectedVendor();
  const { country: countryFromUrl, vendorId: vendorIdFromURL } = useParams<{
    country: string;
    vendorId: string;
  }>();
  const vendorIdRequest = isMultiVendor() || isGlobalPartner() ? vendorIdFromURL : vendorId;
  const countryRequest = isMultiVendor() || isGlobalPartner() ? countryFromUrl : selectedCountry;

  const reportsMfeConfig = useReportsMFEConfig({
    vendorId: vendorIdRequest,
    country: countryRequest,
  });

  return (
    <PageContainer maxWidth="xl">
      <Sidebar
        reportsMenuItems={reportsMfeConfig.data?.menuItems}
        externalReports={reportsMfeConfig.data?.externalReports}
      />

      {isLoading ? (
        <KPIHeaderLoader />
      ) : (
        <KPIDocumentationPageHeader css={{ marginBottom: '$6', marginTop: '32px' }} />
      )}
      {children}
    </PageContainer>
  );
}
