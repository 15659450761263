import APIEndpoint from 'Api/APIEndpoint';

const stoplightConfig = {
  url: 'https://stoplight.io/mocks/bees/bees-partner-apis',

  mockPrefix: {
    [APIEndpoint.GET_VENDORS]: '/64951932',
    [APIEndpoint.BUILD_CSV]: '/64951932',
    [APIEndpoint.GET_CSV]: '/64951932',
    [APIEndpoint.GET_VENDOR_BY_ID]: '/64951932',
    [APIEndpoint.SEND_EMAIL_REQUEST]: '/102492257',
    [APIEndpoint.GET_REPORTS_MFE_CONFIG]: '/64951932',
    [APIEndpoint.GET_EMBED_REPORT]: '/64951932',
    [APIEndpoint.GET_REPORT_GROUP]: '/64951932',
    [APIEndpoint.ACTIVATE_REPORT_BY_REPORT_ID]: '/64951932',
    [APIEndpoint.GET_REPORT_GROUP_BY_ID]: '/64951932',
    [APIEndpoint.UPDATE_REPORT_GROUP_BY_ID]: '/64951932',
    [APIEndpoint.GET_REPORTS_BY_ID]: '/64951932',
    [APIEndpoint.ADD_NEW_REPORT]: '/64951932',
    [APIEndpoint.ADD_NEW_VENDOR]: '/64951932',
    [APIEndpoint.GET_BTGUIDE_BY_ID]: '/64951932',
  },
};

export default stoplightConfig;
