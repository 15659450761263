import { EmptyState, Heading, LoadingBuzz, Paragraph } from '@hexa-ui/components';
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { CardSectionProps } from './CardSection.d';
import { Styled } from './CardSection.style';

function CardSection({
  title,
  subtitle,
  hasRedirect,
  redirectText,
  pagePath,
  children,
  paddingBottom = true,
  isLoading = false,
  isEmpty = false,
}: Readonly<CardSectionProps>): React.ReactElement {
  const { formatMessage } = useIntl();

  const renderContent = () => {
    if (isLoading) {
      return (
        <Styled.CenterContainer>
          <LoadingBuzz />
        </Styled.CenterContainer>
      );
    }

    if (!isLoading && isEmpty) {
      return (
        <Styled.CenterContainer>
          <EmptyState.SectionLevel
            description={formatMessage({ id: 'CARD_SECTION.EMPTY_MESSAGE' })}
          />
        </Styled.CenterContainer>
      );
    }

    return children;
  };

  const history = useHistory();

  const handleClick = () => {
    history.push(pagePath);
  };

  return (
    <Styled.Card border="medium" elevated="small" paddingBottom={paddingBottom}>
      <Styled.HeaderContainer>
        <Styled.TitleContainer>
          <Heading size="H3">{title}</Heading>
          {hasRedirect && (
            <Styled.LinkContainer onClick={handleClick}>{redirectText}</Styled.LinkContainer>
          )}
        </Styled.TitleContainer>
        <Paragraph>{subtitle}</Paragraph>
      </Styled.HeaderContainer>
      {renderContent()}
    </Styled.Card>
  );
}

export default CardSection;
