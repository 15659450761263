import { styled } from '@hexa-ui/theme';

const Container = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  marginBottom: '$6',
});

const GridContainer = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gap: '16px',
  paddingBottom: '$8',
});

const SearchFieldContainer = styled('div', {
  width: '32%',
});

const CenterContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
});

export const Styled = {
  Container,
  GridContainer,
  SearchFieldContainer,
  CenterContainer,
};
