export interface ExternalReportResponse {
  reportCode: string;
  slug: string;
  trackingId: string;
  title: Record<string, string>;
  description: Record<string, string>;
  isExternal: boolean;
  externalUrl?: string;
}

export default class ExternalReport {
  public reportCode: string;

  public slug: string;

  public trackingId: string;

  public readonly title: Record<string, string>;

  private readonly description: Record<string, string>;

  public isExternal: boolean;

  public externalUrl?: string;

  constructor(externalReportResponse: ExternalReportResponse) {
    this.reportCode = externalReportResponse.reportCode;
    this.slug = externalReportResponse.slug;
    this.trackingId = externalReportResponse.trackingId;
    this.title = externalReportResponse.title;
    this.description = externalReportResponse.description;
    this.isExternal = externalReportResponse.isExternal;
    this.externalUrl = externalReportResponse.externalUrl;
  }

  getTitle(locale: string, defaultLocale: string): string {
    return this.title[locale] || this.title[defaultLocale];
  }

  getDescription(locale: string, defaultLocale: string): string {
    return this.description[locale] || this.description[defaultLocale];
  }
}

export const getExternalReportResponseExample = (
  externalReport: Partial<ExternalReportResponse>
): ExternalReportResponse => ({
  reportCode: 'FORCE_COMMERCIAL_FRONTLINE',
  slug: 'commercial-frontline',
  trackingId: 'FORCE_EXECUTIVE',
  title: {
    'en-US': 'Commercial Frontline',
  },
  description: {
    'en-US': 'Report from BEES Force',
  },
  isExternal: false,
  ...externalReport,
});

export const getExternalReportExample = (
  externalReport: Partial<ExternalReportResponse> = {}
): ExternalReport => new ExternalReport(getExternalReportResponseExample(externalReport));
