import { Paragraph } from '@hexa-ui/components';
import { TypeToast, usePreferredLanguageV2, useToast } from 'admin-portal-shared-services';
import AppModal from 'Components/AppModal/AppModal';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useGetWorkspaces } from 'Services/GetWorkspaces/useGetWorkspaces';
import { Styled } from './ChangeWorkspaceModal.style';
import { ChangeWorkspaceModalProps } from './ChangeWorkspaceModal.types';
import { ChangeWorkspaceModalForm } from './ChangeWorkspaceModalForm/ChangeWorkspaceModalForm';

type ChangeWorkspaceModalSubmit = {
  workspaceId: string;
  segmentId: string;
  vendorId?: string;
  country?: string;
  vendorName?: string;
};

export function ChangeWorkspaceModal({
  isOpen,
  onClose,
}: Readonly<ChangeWorkspaceModalProps>): React.ReactElement {
  const { formatMessage } = useIntl();

  const history = useHistory();
  const toastService = useToast();
  const location = useLocation();
  const { preferredLanguage } = usePreferredLanguageV2();

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      workspaceId: '',
      segmentId: '',
      vendorId: '',
      country: '',
    },
  });

  const { updateWorkspace } = useWorkspaceContext();
  const { data: allWorkspaces, isLoading } = useGetWorkspaces();

  /* istanbul ignore next */
  const handleToast = (workspaceId: string) => {
    const getWorkspace = allWorkspaces?.workspaces.find(
      (workspace) => workspace.id === workspaceId
    );
    toastService.notify({
      type: TypeToast.INFO,
      message: `${formatMessage({ id: 'REPORTS_LIST_PAGE.TOAST.FIRST_PART' })}  ${
        getWorkspace?.title[preferredLanguage ?? 'en-US']
      } ${formatMessage({
        id: 'REPORTS_LIST_PAGE.TOAST.LAST_PART',
      })}`,
    });
  };

  const onSubmit = (data: ChangeWorkspaceModalSubmit) => {
    updateWorkspace(data);
    methods.reset();

    handleToast(data.workspaceId);

    onClose();

    if (location.pathname !== '/reports') {
      history.push('/reports');
    }
  };

  const onCloseModal = () => {
    methods.reset();
    onClose();
  };

  return (
    <AppModal
      isOpen={isOpen}
      onClose={onCloseModal}
      onCancelButton={onCloseModal}
      buttonConfirmLabel="CHANGE_WORKSPACE_MODAL.CONFIRM_BUTTON"
      onConfirmButton={methods.handleSubmit(onSubmit)}
      title="CHANGE_WORKSPACE_MODAL.TITLE"
      disableConfirmButton={!methods.formState.isValid}
      css={{ '@tablet': { minWidth: '517px' } }}
      isLoading={isLoading}
    >
      <FormProvider {...methods}>
        <Styled.Container>
          <Paragraph>{formatMessage({ id: 'CHANGE_WORKSPACE_MODAL.DESCRIPTION' })}</Paragraph>
          <ChangeWorkspaceModalForm />
        </Styled.Container>
      </FormProvider>
    </AppModal>
  );
}
