import { Divider, Paragraph, TextLink } from '@hexa-ui/components';
import { ChangeWorkspaceModal } from 'Components/ChangeWorkspaceModal/ChangeWorkspaceModal';
import { useWorkspaceContext } from 'Context/WorkspacesContext/WorkspacesContext';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Logo } from '../Logo/Logo';
import { Styled } from './WorkspaceSelectedCard.style';

export function WorkspaceSelectorCard(): React.ReactElement {
  const { formatMessage, locale: SELECTED_LANGUAGE } = useIntl();

  const [isOpen, setIsOpen] = useState(false);

  const { workspaceData, setTempWorkspaceId } = useWorkspaceContext();

  const handleToggleModal = () => {
    setTempWorkspaceId('');
    setIsOpen(!isOpen);
  };

  const thumb = workspaceData?.segment?.thumbnail;
  const name = workspaceData?.segment?.name || '';

  return (
    <>
      <ChangeWorkspaceModal isOpen={isOpen} onClose={handleToggleModal} />

      <Styled.CardContainer border="small" elevated="small">
        <Styled.CardItem>
          <Paragraph data-testid="workspace-name">
            {workspaceData?.workspace?.title[SELECTED_LANGUAGE]}
          </Paragraph>
        </Styled.CardItem>
        <Divider orientation="vertical" />

        <Styled.CardItem>
          <Logo type={thumb?.type} thumbnail={thumb?.value} name={name} />
          <Paragraph
            data-testid="segment-name"
            weight="semibold"
            size="small"
            css={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              flexGrow: 1,
              maxWidth: 'max-content',
            }}
          >
            {workspaceData?.segment?.name ||
              formatMessage({ id: 'WORKSPACE_SELECTED_CARD.NO_SEGMENT' })}
          </Paragraph>
        </Styled.CardItem>
        {workspaceData?.vendorName && (
          <>
            <Divider orientation="vertical" />
            <Styled.CardItem>
              <Paragraph data-testid="workspace-vendor-name">{workspaceData?.vendorName}</Paragraph>
            </Styled.CardItem>
          </>
        )}
        <Divider orientation="vertical" css={{ backgroundColor: 'transparent' }} />
        <Styled.CardItem>
          <TextLink onClick={handleToggleModal} hasUnderline={false}>
            {formatMessage({ id: 'WORKSPACE_SELECTED_CARD.CHANGE' })}
          </TextLink>
        </Styled.CardItem>
      </Styled.CardContainer>
    </>
  );
}
