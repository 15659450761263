import MessageMap from 'I18n/i18n';

const id: MessageMap = {
  test: {
    translate: 'Translate',
    missing: 'Only Indonesian option available',
  },

  SELECTED_LANGUAGE: 'id-ID',

  // NEED COPYDECK
  MAIN_PAGE: {
    TITLE: 'Analytics',
    BTGUIDE_SECTION: {
      TITLE: 'Business transformation guide',
      DESCRIPTION: 'A step-by-step guide to maximize value with BEES.',
      LINK: 'Go to Business transformation guide',
    },
    REPORT_SECTION: {
      TITLE: 'Reports',
      SUBTITLE: 'Explore detailed reports on different themes.',
      BUTTON: 'Show all reports',
    },
    WORKSPACE_SECTION: {
      TITLE: 'Workspaces',
      DESCRIPTION: 'Access different sets of data available to you.',
      BUTTON: 'Show all workspaces',
    },
    EMPTY_STATE: {
      TITLE: 'Oops',
      DESCRIPTION: 'Apparently there is no workspace created or available for you.',
    },
  },

  // NEED COPYDECK
  CARD_SECTION: {
    EMPTY_MESSAGE: 'Oops, sorry! No results found.',
  },

  WORKSPACES_PAGE: {
    // NEED COPY DECK
    TITLE: 'Workspaces',
    SEARCHFIELD: {
      PLACEHOLDER: 'Search by workspace name',
    },
  },

  HEADER: {
    ANALYTICS_BUTTON: 'Analytics admin',
  },

  WORKSPACE_SELECTED_CARD: {
    CHANGE: 'Change',
    NO_SEGMENT: 'No segment selected',
  },

  CHANGE_WORKSPACE_MODAL: {
    TITLE: 'Change workspace',
    DESCRIPTION: 'Select a workspace and segment.',
    WORKSPACE: {
      LABEL: 'Workspace',
      PLACEHOLDER: 'Select workspace',
    },
    SEGMENT: {
      LABEL: 'Segment',
      PLACEHOLDER: 'Select segment',
    },
    COUNTRY: {
      LABEL: 'Country',
      PLACEHOLDER: 'Select country',
    },
    SERVICE_MODEL: {
      LABEL: 'Business model',
      PLACEHOLDER: 'Select a business model',
    },
    VENDOR: {
      LABEL: 'Vendor',
      PLACEHOLDER: 'Select a vendor',
      LOADING: 'Loading...',
    },
    CONFIRM_BUTTON: 'Change',
  },

  WORKSPACE_CARD: {
    SINGULAR_TEXT: 'segment',
    PLURAL_TEXT: 'segments',
  },

  BREADCRUMBS: {
    REPORTS: 'Get Started',
  },

  REPORTS_LIST_PAGE: {
    TITLE: 'Reports',
    SEARCH: {
      PLACEHOLDER: 'Search by theme or report name',
    },
    NOT_FOUND_STATE: {
      TITLE: 'No results found',
      TEXT: 'Check your spelling or try using different search terms.',
    },
    TOAST: {
      FIRST_PART: "You're now in the",
      LAST_PART: 'workspace',
    },
    REPORTS_QUANTITY: ' reports',
  },

  REPORTS_PAGE: {
    REPORTS_PAGE_TITLE: 'Data dan analitik',
    REPORTS_PAGE_VENDOR: 'Vendor',
    CONTENT_REPORTS_TAB_VIEW:
      'Measure your performance across all delivered orders within the selected timeframe.',
  },

  GLOBAL_OVERVIEW_PAGE: {
    GLOBAL_OVERVIEW_PAGE_TITLE: 'Ikhtisar global',
  },

  CONTAINER_REPORTS: {
    CONTAINER_ERROR: 'Terjadi kesalahan',
    CONTAINER_ERROR_LABEL: 'Mohon maaf, kami mengalami masalah teknis. Cobalah lagi.',
  },

  SELECT_RANGE_DATE_CSV_MODAL: {
    TITLE: 'Ekspor file CSV',
    DESCRIPTION: 'Pilih data yang ingin Anda ekspor.',
    YEAR_LABEL: 'Tahun',
    MONTH_LABEL: 'Bulan',
    NO_INFO_AVAILABLE: 'Tidak ada informasi untuk periode yang dipilih. Cobalah tanggal yang lain.',
    CANCEL: 'Batalkan',
    DOWNLOAD: 'Ekspor',
  },

  BECOME_PREMIUM_MODAL: {
    TITLE: 'Business insights taken to the next level',
    FIRST_DESCRIPTION: 'Get access to CSV export and many other features to improve your analysis.',
    BUTTON_BECOME: 'Become a Gold Partner',
    BUTTON_LATER: 'Later',
    MODAL_STYLE: 'EN',
  },

  CSV_DOWNLOAD_SELECT: {
    PLACEHOLDER: 'Ekspor file',
  },

  CSV_SILVER_MODAL: {
    TITLE: 'Your free trial will expire soon',
    FIRST_DESCRIPTION:
      'This CSV you are about to download is a courtesy from our membership program.',
    SECOND_DESCRIPTION:
      'To keep access to special insights tailored for your company in the future, you’ll have to upgrade to Gold Partner.',
    BUTTON: 'DOWNLOAD FILE',
    NO_INFO_AVAILABLE: "There's no information for the period.",
  },

  HELP_CENTER_MODAL: {
    TITLE: 'Cara mengakses file CSV',
    DESCRIPTION:
      'Kurang familier dengan file CSV? Bacalah panduan langkah demi langkah untuk menggunakan file CSV.',
    LINK: 'Tampilkan panduan file CSV',
    PROCEED_BUTTON: 'Lanjut mengunduh',
  },

  GET_STARTED: {
    LOADING: 'Memuat',
    TITLE: 'Memulai',
    PARAGRAPH: 'Pilih vendor untuk melihat data dan wawasan mereka.',
    NEXT: 'Berikutnya',
  },

  SELECTED_VENDOR: {
    CHANGE: 'Ubah',
    TITLE: 'You are viewing',
  },

  SELECT_TOOLTIP: {
    OPEN: 'Open',
    CLEAR: 'Clear',
  },

  MONTH: {
    1: 'Januari',
    2: 'Februari',
    3: 'Maret',
    4: 'April',
    5: 'Mei',
    6: 'Juni',
    7: 'Juli',
    8: 'Agustus',
    9: 'September',
    10: 'Oktober',
    11: 'November',
    12: 'Desember',
  },

  COMMON: {
    CANCEL: 'Batalkan',
    CONFIRM: 'Confirm',
  },
  LOADING: {
    TITLE: 'Memuat',
    PARAGRAPH: 'Kami sedang menyiapkan halaman untuk Anda.',
  },
  SELECT_VENDOR: {
    TITLE: 'Pilih vendor',
  },
  UNAUTHORIZED: {
    ERROR_TITLE: 'Hmm… Akses ditolak',
    ERROR_DESCRIPTION: 'Sepertinya Anda tidak memiliki izin untuk mengakses halaman ini',
  },
  EXPORT_FILE: {
    SOMETHING_WRONG: 'Something went wrong. Please try again.',
    PREPARING_DOWNLOAD: 'Mempersiapkan file CSV untuk diunduh.',
    SUCCESS_DOWNLOAD: 'Unduhan berhasil diselesaikan',
  },
  // NEED COPY DECK
  BECOME_PREMIUM: {
    PAGE_TITLE: 'Become Premium',
    TITLE_NORMAL: `We've got a plan\nthat's perfect for`,
    TITLE_HIGHLIGHTED: 'your business',
    SUBTITLE: 'Power up your analysis with the right membership for you company.',
    SILVER_PARTNER_CARD: {
      TITLE: 'Silver Partner',
      DESCRIPTION: 'For businesses starting in the digital marketplace world.',
      BIG_TEXT: 'Basic',
      BUTTON_LABEL: 'Current plan',
    },
    GOLD_PARTNER_CARD: {
      TITLE: 'Gold Partner',
      DESCRIPTION:
        'For businesses with advanced data intelligence teams that want to invest in targeted audiences.',
      BIG_TEXT: 'Premium',
      BUTTON_LABEL: 'Contact us',
    },
    METRICS_TABLE: {
      HEADER: {
        METRICS: 'Metrics',
        SILVER: 'Silver',
        GOLD: 'Gold',
      },
      ROWS: {
        UNITS_DELIVERED: 'Units Delivered',
        ORDERS_DELIVERED: 'Orders Delivered',
        BUYERS: 'Pembeli',
        UNIT_DELIVERED_BY_PRODUCT: 'Unit Delivered by Product',
        UNITS_DELIVERED_BY_CITY_AND_STATE: 'Units Delivered by City and State',
        UNITS_SOLD_BY_DISTRIBUTION_CENTER: 'Units Sold by Distribution Center',
        TOP_5_PRODUCTS: 'Top 5 Products',
        MONTHLY_EVOLUTION_OF_ORDERS: 'Monthly evolution of Orders',
        REVENUE: 'Pendapatan',
        AVERAGE_REVENUE_PER_ORDER: 'Average Revenue per Order',
        AVERAGE_REVENUE_PER_BUYER: 'Average Revenue per Buyer',
        REVENUE_BY_REGION: 'Revenue by Region',
        MONTHLY_SALES_REVENUE: 'Monthly Sales Revenue',
        CURRENT_MONTH_VS_LAST_MONTH: 'Current Month vs Last Month',
        ORDERS_VS_REVENUE_STATE: 'Orders vs Revenue/State',
        ORDERS_VS_REVENUE_SEGMENT: 'Orders vs Revenue/Segment',
        ORDERS_VS_REVENUE_PRODUCT: 'Orders vs Revenue/Product',
        REVENUE_BY_PRODUCT: 'Revenue by Product',
        REVENUE_BY_SEGMENT: 'Revenue by Segment',
        REVENUE_BY_CHANNEL: 'Revenue by Channel',
      },
    },
    CSV_DOWNLOAD_TABLE: {
      HEADER: {
        CSV_DOWNLOAD: 'CSV Download',
        SILVER: 'Silver',
        GOLD: 'Gold',
      },
      ROWS: {
        DATE: 'Date',
        GROSS_VALUE: 'Gross Value',
        REGION_STATE_AND_CITY: 'Region (State and City)',
        DISTRIBUTION_CENTER: 'Distribution Center',
        SEGMENT: 'Segment',
        SUB_SEGMENT: 'Sub-Segment',
        CHANNEL: 'Saluran',
      },
    },
  },
  REVENUE_SILVER_PARTNER: {
    TITLE: 'Business insights taken to the',
    TITLE_NEXT_LEVEL: 'next level',
    DESCRIPTION:
      'With our premium insights you can discover powerful analysis that will unleash all the potential of your business.',
    BUTTON: 'Become a Gold Partner',
  },
  // NEED COPYDECK
  BADGES: {
    EXCLUSIVE_FOR_GOLD_PARTNER: 'Exclusive for Gold Partners',
  },
  // NEED COPYDECK
  TIER_IDENTIFICATION: {
    GOLD: 'Gold Partner',
    SILVER: 'Silver Partner',
  },
  CONTACT_US: {
    TOAST_SUCCESS: 'Request successfully sent! Our team will contact you shortly.',
    TOAST_ERROR: 'Something went wrong. Please try again.',
  },
  COUNTRIES: {
    AR: 'Argentina',
    BO: 'Bolivia',
    BR: 'Brasil',
    CL: 'Chili',
    CO: 'Kolumbia',
    DO: 'Republik Dominika',
    EC: 'Ekuador',
    ID: 'Indonesia',
    MX: 'Meksiko',
    PA: 'Panama',
    PE: 'Peru',
    PH: 'Filipina',
    PY: 'Paraguay',
    ZA: 'Afrika Selatan',
    CH: 'Switzerland',
    GLOBAL_OVERVIEW: 'Ikhtisar global',
  },
  COUNTRY_SELECT: {
    PLACEHOLDER: 'Pilih negara',
  },
  SERVICE_MODELS: {
    '1P': '1P',
    '3P-3L-SS': '3P Pro',
    '3P-3L-SF': 'DTaaS',
    '3P': 'DTaaS',
  },
  SERVICE_MODEL_SELECT: {
    PLACEHOLDER: 'Pilih model bisnis',
  },
  VENDOR_SELECT: {
    PLACEHOLDER: 'Pilih vendor',
    LOADING: 'Memuat...',
  },

  EMBED_REPORT: {
    LOADING_EMBED_REPORT: {
      TITLE: 'Mengambil data',
      BODY: 'Langkah 1 dari 3',
    },

    LOADING_POWERBI: {
      TITLE: 'Memuat laporan',
      BODY: 'Langkah 2 dari 3',
    },

    LOADING_DATA: {
      TITLE: 'Hampir selesai',
      BODY: 'Langkah 3 dari 3',
    },
  },

  SIDEBAR: {
    GET_START: 'Get started',
    WORKSPACES: 'Workspaces',
    ANALYTICS_ADMIN: 'Analytics Admin',
    MAIN_PAGE: 'Main page',
  },

  EXTERNAL_REPORTS_PAGE: {
    TITLE: 'Laporan & dokumentasi tambahan',
    DESCRIPTION: 'Akses laporan tambahan dari produk BEES lain.',
  },

  KPI_DOCUMENTATION_PAGE: {
    TITLE: 'Memahami KPI kita',
    SUBTITLE:
      'Laman ini menjelaskan pengertian KPI kita (indikator kinerja utama) dan cara menghitung KPI. Untuk mencari KPI tertentu, gunakan bilah pencarian atau navigasi melalui tab di bawah.',
    COLUMNS: {
      NAME: 'Nama KPI',
      DESCRIPTION: 'Deskripsi',
      METHODOLOGY: 'Metodologi perhitungan',
    },
    EMPTY_STATE: 'Oops, sorry! Tidak ada hasil yang ditemukan.',
    SEARCH_FIELD: 'Cari berdasarkan KPI atau kata kunci',
    SEARCH_RESULTS: {
      EMPTY_STATE_TITLE: 'Tidak ada hasil yang ditemukan.',
      EMPTY_STATE_TEXT: 'Periksa ejaan Anda atau coba gunakan istilah pencarian lain.',
      COUNT_RESULT_MESSAGE: ' hasil untuk ',
      UNIT_RESULT_MESSAGE: '1 hasil untuk ',
    },
  },
};

export default id;
